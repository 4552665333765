<template>
  <div class="homepage-hero">
    <div class="homepage-hero__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12"></div>
          <div class="col-lg-8 col-md-12 col-12">
            <h1 class="bannertext">The Lord is my Shepherd</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media (max-width: 2560px) and (min-width: 2001px) {
  h1.bannertext {
    font-size: 145px;
  }
  .homepage-hero__wrapper {
    min-height: 60rem !important;
  }
  .homepage-hero {
    height: 83rem !important;
  }
}
@media (max-width: 2000px) and (min-width: 1501px) {
  h1.bannertext {
    font-size: 145px;
  }
  .homepage-hero__wrapper {
    min-height: 50rem !important;
  }
  .homepage-hero {
    height: 69rem !important;
  }
}

@media (max-width: 1500px) and (min-width: 1401px) {
  h1.bannertext {
    font-size: 145px;
  }
  .homepage-hero__wrapper {
    min-height: 50rem !important;
  }
  .homepage-hero {
    height: 50rem !important;
  }
}
@media (max-width: 1400px) and (min-width: 1301px) {
  h1.bannertext {
    font-size: 110px;
  }
  .homepage-hero__wrapper {
    min-height: 40rem !important;
  }
  .homepage-hero {
    height: 58rem !important;
  }
}
@media (max-width: 1300px) and (min-width: 1201px) {
  h1.bannertext {
    font-size: 100px;
  }
  .homepage-hero__wrapper {
    min-height: 30rem !important;
  }
  .homepage-hero {
    height: 44rem !important;
  }
}
@media (max-width: 1200px) and (min-width: 1100px) {
  h1.bannertext {
    font-size: 100px;
  }
  .homepage-hero__wrapper {
    min-height: 30rem !important;
  }
  .homepage-hero {
    height: 45rem !important;
  }
}
@media (max-width: 1099px) and (min-width: 1000px) {
  h1.bannertext {
    font-size: 100px;
  }
  .homepage-hero__wrapper {
    min-height: 25rem !important;
  }
  .homepage-hero {
    height: 41rem !important;
  }
}
@media (max-width: 999px) and (min-width: 900px) {
  h1.bannertext {
    font-size: 85px;
  }
  .homepage-hero__wrapper {
    min-height: 20rem !important;
  }
  .homepage-hero {
    height: 33rem !important;
  }
}
@media (max-width: 899px) and (min-width: 850px) {
  h1.bannertext {
    font-size: 75px;
  }
  .homepage-hero__wrapper {
    min-height: 15rem !important;
  }
  .homepage-hero {
    height: 25rem !important;
  }
}
@media (max-width: 849px) and (min-width: 821px) {
  h1.bannertext {
    font-size: 75px;
  }
  .homepage-hero__wrapper {
    min-height: 14rem !important;
  }
  .homepage-hero {
    height: 20rem !important;
  }
}
@media (max-width: 820px) and (min-width: 800px) {
  h1.bannertext {
    font-size: 105px;
  }
  .homepage-hero__wrapper {
    min-height: 45rem !important;
  }
  .homepage-hero {
    height: 75rem !important;
  }
}
@media (max-width: 799px) and (min-width: 780px) {
  h1.bannertext {
    font-size: 75px;
  }
  .homepage-hero__wrapper {
    min-height: 10rem !important;
  }
  .homepage-hero {
    height: 20rem !important;
  }
}
@media (max-width: 779px) and (min-width: 760px) {
  h1.bannertext {
    font-size: 105px;
  }
  .homepage-hero__wrapper {
    min-height: 35rem !important;
  }
  .homepage-hero {
    height: 60rem !important;
  }
}
@media (max-width: 759px) and (min-width: 750px) {
  h1.bannertext {
    font-size: 75px;
  }
  .homepage-hero__wrapper {
    min-height: 10rem !important;
  }
  .homepage-hero {
    height: 20rem !important;
  }
}
@media (max-width: 749px) and (min-width: 730px) {
  h1.bannertext {
    font-size: 60px;
  }
  .homepage-hero__wrapper {
    min-height: 0rem !important;
  }
  .homepage-hero {
    height: 21rem !important;
  }
}
@media (max-width: 729px) and (min-width: 700px) {
  h1.bannertext {
    font-size: 105px;
  }
  .homepage-hero__wrapper {
    min-height: 45rem !important;
  }
  .homepage-hero {
    height: 67rem !important;
  }
}
@media (max-width: 699px) and (min-width: 631px) {
  h1.bannertext {
    font-size: 60px;
  }
  .homepage-hero__wrapper {
    min-height: 0rem !important;
  }
  .homepage-hero {
    height: 20rem !important;
  }
}
@media (max-width: 630px) and (min-width: 610px) {
  h1.bannertext {
    font-size: 85px;
  }
  .homepage-hero__wrapper {
    min-height: 30rem !important;
  }
  .homepage-hero {
    height: 44rem !important;
  }
}
@media (max-width: 609px) and (min-width: 580px) {
  h1.bannertext {
    font-size: 85px;
  }
  .homepage-hero__wrapper {
    min-height: 30rem !important;
  }
  .homepage-hero {
    height: 50rem !important;
  }
}
@media (max-width: 579px) and (min-width: 500px) {
  h1.bannertext {
    font-size: 50px;
    padding: 20px;
  }
  .homepage-hero__wrapper {
    min-height: 0rem !important;
  }
  .homepage-hero {
    height: 20rem !important;
  }
}
@media (max-width: 499px) and (min-width: 471px) {
  h1.bannertext {
    font-size: 70px;
    padding: 20px;
  }
  .homepage-hero__wrapper {
    min-height: 20rem !important;
  }
  .homepage-hero {
    height: 39rem !important;
  }
}
@media (max-width: 470px) and (min-width: 420px) {
  h1.bannertext {
    font-size: 80px;
    padding: 20px;
  }
  .homepage-hero__wrapper {
    min-height: 35rem !important;
  }
}
@media (max-width: 419px) and (min-width: 401px) {
  h1.bannertext {
    font-size: 65px;
    padding: 30px;
  }
  .homepage-hero__wrapper {
    min-height: 20rem !important;
  }
  .homepage-hero {
    height: 42rem !important;
  }
}
@media (max-width: 400px) and (min-width: 372px) {
  h1.bannertext {
    font-size: 65px;
    padding: 30px;
  }
  .homepage-hero__wrapper {
    min-height: 20rem !important;
  }
  .homepage-hero {
    height: 38rem !important;
  }
}
@media (max-width: 371px) and (min-width: 321px) {
  h1.bannertext {
    font-size: 65px;
    padding: 30px;
  }
  .homepage-hero__wrapper {
    min-height: 0rem !important;
  }
  .homepage-hero {
    height: 37rem !important;
  }
}
@media (max-width: 320px) and (min-width: 0px) {
  h1.bannertext {
    font-size: 60px;
    padding: 30px;
  }
  .homepage-hero__wrapper {
    min-height: 20rem !important;
  }
  .homepage-hero {
    height: 37rem !important;
  }
}
.homepage-hero {
  padding: 50px 0px;
  background: url("../assets/images/Banner-Home.jpg");
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 40rem;
}
.bannertext {
  z-index: 2;
  margin: 0px auto;
  color: #fff;
  text-align: center;
  font-size: 100px;
}
.homepage-hero__wrapper {
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px auto;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  min-height: 35rem;
}
</style>
