<template>
  <Header />
  <Herosection />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Herosection from "@/components/HeroSection.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Herosection,
    Footer,
  },
};
</script>
